<script>
import SearchAndActions from "@/components/Table/SearchAndActions.vue";
import Header from "@/components/Table/Header.vue";
import axios from "axios";
import DataTable from "@/components/Table/DataTable.vue";
import ActionsButton from "@/components/Table/ActionsButton.vue";
import TablePagination from "@/components/Table/TablePagination.vue";
import {mapGetters} from "vuex";

export default {
  name: "MeterConsumption",
  components: {TablePagination, ActionsButton, DataTable, Header, SearchAndActions},
  data() {
    return {
      breadcrumbs: [{text: "Home", path: "/dashboard", isActive: false}, {
        text: "Meter Errors Log",
        path: "/meter-errors-log",
        isActive: true
      }],
      tableData: [],
      pagination: {
        current_page: 1,
        per_page: 10,
        total: 0,
        last_page: 0,
      },
      search: null,
      sort: '-created_at',
      date: null,
      filter: {
        project: null,
        status: null,
        type: null,
      }
    }
  },
  computed: {
    ...mapGetters("Projects", ["projectsList"])
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
  mounted() {
    let page = this.$route.query.page ? this.$route.query.page : 1;
    let per_page = this.$route.query.per_page ? this.$route.query.per_page : 10;
    let search = this.$route.query.search ? this.$route.query.search : null;
    let sort = this.$route.query.sort ? this.$route.query.sort : '-created_at';
    let date = this.$route.query.date ? this.$route.query.date : null;
    let project = null;
    let status = this.$route.query.status ? this.$route.query.status : null;
    let type = this.$route.query.type ? this.$route.query.type : null;

    this.getListData(page, per_page, search, sort, date, project, status, type);

    this.$store.dispatch("Projects/getProjectsList");
  },
  methods: {
    getListData(page = 1, per_page = 10, search = null, sort = '-created_at', date = null, project = null, status = null, type = null) {
      let url = '/admin/meter-errors-log-list';
      let params = {
        page: page,
        per_page: per_page ? per_page : this.pagination.per_page,
        search: search ? search : this.search,
        sort: sort,
        date: date ? date : this.date,
        project: project ? project.id : this.filter.project ? this.filter.project.id : null,
        status: status ? status : this.filter.status ? this.filter.status : null,
        type: type ? type : this.filter.type ? this.filter.type : null,
      };

      axios.get(url, {params})
          .then(response => {
            this.tableData = response.data.data;
            this.pagination = response.data.meta;
            this.sort = sort;

            this.$router.replace({query: params});
          })
    },
    onPageChange(page) {
      this.getListData(page, this.pagination.per_page, this.search, this.sort, this.date, this.filter.project, this.filter.status, this.filter.type);
    },
    onPageSizeChanged(pageSize) {
      this.getListData(1, pageSize, this.search, this.sort, this.date, this.filter.project, this.filter.status, this.filter.type);
    },
    onSearch(search) {
      this.search = search ? search : null;
      this.getListData(1, this.pagination.per_page, this.search, this.sort, this.date, this.filter.project, this.filter.status, this.filter.type);
    },
    sortBy(field) {
      let sort = this.sort === field ? '-' + field : field;
      this.getListData(1, this.pagination.per_page, this.search, sort, this.date, this.filter.project, this.filter.status, this.filter.type);
    },
    onDate(date) {
      this.date = date ? date.join(',') : null;
      this.getListData(1, this.pagination.per_page, this.search, this.sort, this.date, this.filter.project, this.filter.status, this.filter.type);
    },
    onFilterProject(project) {
      this.filter.project = project ? project : null;
      this.resetData();
    },
    closeFilterProject() {
      this.filter.project = null;
      this.resetData();
    },
    onFilterStatus(status) {
      this.filter.status = status ? status : null;
      this.resetData();
    },
    closeFilterStatus() {
      this.filter.status = null;
      this.resetData();
    },
    onFilterType(type) {
      this.filter.type = type ? type : null;
      this.resetData();
    },
    closeFilterType() {
      this.filter.type = null;
      this.resetData();
    },
    resetData() {
      this.getListData(1, this.pagination.per_page, this.search, this.sort, this.date, this.filter.project, this.filter.status, this.filter.type);
    },
    updateStatus(error, status) {
      this.$store.dispatch("Settings/loading", true);
      this.$store
          .dispatch("Meter/updateMeterErrorStatus", {
            errorId: error.id,
            status: status,
          })
          .then(
              () => {
                this.$store.dispatch("Settings/loading", false);
                this.$toastr.s(
                    "Meter Error status successfully updated.",
                    "Success!"
                );
              },
              (err) => {
                // console.log(err.response);
                this.$store.dispatch("Settings/loading", false);
                this.$toastr.e(err.response.data.message, "Failed!");
              }
          );
    },
  }
}
</script>

<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h6 class="text-uppercase font-weight-bold text-dark">METER ERROR LOGS</h6>
      </div>

      <div class="col-md-12">
        <div class="card p-4">
          <SearchAndActions
              :show-import="false"
              @search="onSearch"
              @date="onDate"
              action-url="/admin/meter-errors-log-list"
              download-file-name="meter-errors-log"
          >
            <div class="ml-2">
              <el-dropdown @command="onFilterProject">
                <el-button size="mini">
                  <svg style="width: 15px; height: 15px" xmlns="http://www.w3.org/2000/svg" fill="none"
                       viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z"/>
                  </svg>
                  <span class="ml-1">Project</span>
                </el-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item
                        v-for="project in projectsList"
                        :key="project.id"
                        :command="project"
                    >
                      {{ project.name }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
            <div class="ml-2">
              <el-dropdown @command="onFilterStatus">
                <el-button size="mini">
                  <svg style="width: 15px; height: 15px" xmlns="http://www.w3.org/2000/svg" fill="none"
                       viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z"/>
                  </svg>
                  <span class="ml-1">Status</span>
                </el-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item command="pending">Pending</el-dropdown-item>
                    <el-dropdown-item command="working">Working</el-dropdown-item>
                    <el-dropdown-item command="solved">Solved</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
            <div class="ml-2">
              <el-dropdown @command="onFilterType">
                <el-button size="mini">
                  <svg style="width: 15px; height: 15px" xmlns="http://www.w3.org/2000/svg" fill="none"
                       viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round"
                          d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z"/>
                  </svg>
                  <span class="ml-1">Type</span>
                </el-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item command="low_battery">Low Battery</el-dropdown-item>
                    <el-dropdown-item command="leakage">Leakage</el-dropdown-item>
                    <el-dropdown-item command="permanent">Permanent</el-dropdown-item>
                    <el-dropdown-item command="manipulation">Manipulation</el-dropdown-item>
                    <el-dropdown-item command="dry">Dry</el-dropdown-item>
                    <el-dropdown-item command="backflow">Backflow</el-dropdown-item>
                    <el-dropdown-item command="burst">Burst</el-dropdown-item>
                    <el-dropdown-item command="data_discontinuation">Data Discontinuation</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>

            <template #filter>
              <div class="col-md-12 mt-2">
                <el-tag
                    v-if="filter.project"
                    closable
                    size="mini"
                    @close="closeFilterProject"
                    class="mr-2"
                >
                  {{ filter.project.name }}
                </el-tag>
                <el-tag
                    v-if="filter.status"
                    closable
                    size="mini"
                    type="success"
                    @close="closeFilterStatus"
                    class="mr-2"
                >
                  {{ filter.status }}
                </el-tag>
                <el-tag
                    v-if="filter.type"
                    closable
                    size="mini"
                    type="warning"
                    @close="closeFilterType"
                    class="mr-2"
                >
                  {{ filter.type }}
                </el-tag>
              </div>
            </template>
          </SearchAndActions>

          <div class="row">
            <div class="col-md-12">
              <DataTable>
                <template #header>
                  <Header :sort="false" title="Sl"/>
                  <Header active="meter" :ordering="sort" @sortBy="sortBy('meter')" title="Meter No"/>
                  <Header active="error_time" :ordering="sort" @sortBy="sortBy('error_time')" title="Error Time"/>
                  <Header active="type" :ordering="sort" @sortBy="sortBy('type')" title="Type"/>
                  <Header active="port_no" :ordering="sort" @sortBy="sortBy('port_no')" title="Port No"/>
                  <Header active="description" :ordering="sort" @sortBy="sortBy('description')" title="PDescription"/>
                  <Header active="status" :ordering="sort" @sortBy="sortBy('status')" title="Status"/>
                  <Header :sort="false" title="Actions"/>
                </template>

                <tr
                    v-for="(data, index) in tableData"
                    :key="data.id"
                >
                  <th scope="row">
                    {{ pagination.from + index }}
                  </th>
                  <td>
                    {{ data.meter_serial_no }}
                  </td>
                  <td>
                    {{ data.error_time | dateFormat }}
                  </td>
                  <td>
                    {{ data.type }}
                  </td>
                  <td>
                    {{ data.port_no }}
                  </td>
                  <td>
                    {{ data.description }}
                  </td>
                  <td>
                    <span
                        class="badge p-2"
                        :class="
                            data.status === 'PENDING'
                              ? 'badge-danger'
                              : data.status === 'WORKING'
                              ? 'badge-warning'
                              : 'badge-success'
                          "
                    >{{ data.status }}</span
                    >
                    <p
                        v-if="data.status === 'SOLVED'"
                        class="m-0 text-warning font-weight-bold"
                    >
                      <i>Solved at {{ data.solved_at | dateFormat }}</i>
                    </p>
                  </td>
                  <td>
                    <ActionsButton>
                      <router-link
                          :to="'/meters/' + data.meter_id"
                      >Locate Meter
                      </router-link
                      >
                      &nbsp;&nbsp;
                      <CDropdown
                          color="primary"
                          toggler-text="Update Status"
                          class="cdropdown"
                          size="sm"
                          v-if="
                            data.type === 'DATA_DISCONTINUATION' &&
                              data.status !== 'SOLVED'
                          "
                      >
                        <CDropdownItem @click="updateStatus(data, 'WORKING')"
                        >Working
                        </CDropdownItem
                        >
                        <CDropdownItem @click="updateStatus(data, 'SOLVED')"
                        >Solved
                        </CDropdownItem
                        >
                      </CDropdown>
                    </ActionsButton>
                  </td>
                </tr>
              </DataTable>

              <div class="mt-5" v-if="tableData.length">
                <TablePagination
                    :pagination="pagination"
                    @pagechanged="onPageChange"
                    @pagesizechanged="onPageSizeChanged"
                />
              </div>

              <div v-else>
                <p class="text-center font-weight-bold">
                  No data found
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>